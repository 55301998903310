/* current */
:root {
  --font-dark: #3f3f46;
  --font-light: #fafafa;
  --gray-light: #f9fafb;
  --gray-dark: #4b5563;
  --gray-med: #9ca3af;
  --grid-color: #d1d5db;
  --axis-color: #3f3f46;
  --background-color: var(--gray-light);
  --background-dark: #0c4a6e;
  --bar-color: #0c4a6e;
  --line-color: #0284c7;
  --light-blue: #1470cc;

  --select-bg: #fff;
  --select-to-color: var(--gray-light);

  --action-bar-height: 40px;
  --picker-top-inset: 16vh;
  --picker-bottom-inset: 12vh;

  --horizontal-padding: 24px;

  --para-font-family: Tahoma, Verdana, sans-serif;
  --font-monospace: Monaco, Helvetica, monospace;
}

@media (prefers-color-scheme: dark) {
  :root {
    --font-dark: #fafafa;
    --font-light: #fafafa;
    --background-color: #1d1d20;
    --gray-dark: #fafafa;
    --gray-med: #9ca3af;
    --grid-color: #d1d5db;
    --axis-color: #fafafa;
    --background-dark: #1d1d20;
    /* --bar-color: #0284c7; */
    --bar-color: #0295DE;
    --line-color: #d1d5db;
    /* --light-blue: #0284c7; */
    /* --light-blue: #0284c7; */
    --light-blue: #49C1FD;
    /* --light-blue: #1470cc; */

    --select-bg: var(--background-color);
    --select-to-color: var(--background-color);
  }
}
/* :root {
  --font-dark: #3F3F46;
  --font-light: #FAFAFA;
  --gray-light: #F9FAFB;
  --gray-dark: #4B5563;
  --gray-med: #9CA3AF;
  --grid-color: #D1D5DB;
  --axis-color: #3F3F46;
  --background-dark: #0C4A6E;
  --bar-color: #0C4A6E;
  --line-color: #0284C7;
  --light-blue: #0284C7;

  --action-bar-height: 40px;
  --picker-top-inset: 16vh;
  --picker-bottom-inset: 12vh;
} */

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--font-dark);
  background-color: var(--background-dark);
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

#container {
  background-color: var(--background-color);
}

.actionbar {
  display: flex;
  background-color: var(--background-color);
  border-bottom: 1px solid var(--grid-color);
  width: calc(100% - (var(--horizontal-padding) * 2));
  position: fixed;
  justify-content: left;
  padding: 8px var(--horizontal-padding) 8px var(--horizontal-padding);
  height: var(--action-bar-height);
  z-index: 5;
}

.actionbar > select {
  margin-left: auto;
  width: 20%;
}

.actionbar > div.state-outline-container {
  width: 52%;
  max-width: 600px;
  display: flex;
}

.state-outline,
.state-button {
  flex: 1;
  display: flex;
  min-width: 0;
  min-height: 0;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  cursor: pointer;
  transition: translate 0.16s cubic-bezier(0.165, 0.84, 0.44, 1);
  /* transition: translate .16s cubic-bezier(0.42, 0, 0.58, 1); */
}

.state-button {
  flex-direction: column;
}

.state-button > div:first-child {
  flex: 2 1;
  min-width: 0;
  min-height: 0;
  width: 100%;
  overflow: hidden;
}

.state-button > div:nth-child(2) {
  flex: 1 2;
  font-size: 10px;
}

.state-outline path,
.state-button path {
  fill: var(--gray-med);
}

.state-outline:hover,
.state-outline:focus,
.state-outline.selected,
.state-button:hover .outline-container,
.state-button:focus .outline-container,
.state-button.selected .outline-container {
  translate: 0 -2px;
}

.state-outline:hover path,
.state-outline:focus path,
.state-outline.selected path,
.state-button:hover path,
.state-button:focus path,
.state-button.selected path {
  fill: var(--light-blue);
}
.state-outline:focus,
.state-button:focus {
  outline: none;
}

.state-outline > div,
.state-button > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.state-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px var(--horizontal-padding) 0 var(--horizontal-padding);
}

.state-summary > div {
  width: 72%;
}

.name-container {
  display: flex;
  align-items: center;
}

.summary-container {
  display: flex;
}

.summary-disclaimer {
  display: flex;
  justify-content: flex-end;
  color: var(--gray-dark);
  padding-top: 4px;
}

.summary-disclaimer > * {
  display: inline-block;
  line-height: 24px;
  font-size: 12px;
}

.summary-container > div {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.summary-container > div:not(:last-child) {
  border-right: 1px solid var(--gray-med);
}

.summary-container h3 {
  margin-top: 0;
  font-size: 1rem;
  margin-bottom: 8px;
}

.fetchable {
  /* transition: filter .18s ease-in; */
  transition: all 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  filter: blur(0px);
}

.fetchable-fetching {
  transition: all 0.08s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* transform: scale(0.9); */
  filter: blur(6px);
}

.summary-value span {
  display: inline-block;
  font-family: var(--para-font-family);
  color: var(--light-blue);
}

.summary-value .summary-value-step {
  font-size: 0.8em;
  padding-left: 8px;
}

#container {
  min-height: 1000px;
}

.chart-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 var(--horizontal-padding);
}

.chart {
  flex: 1;
  min-width: 0;
  min-height: 0;
  overflow: hidden;
  flex-basis: calc(50% - 16px);
  padding: 16px 8px;
}

.chart h2 {
  padding-left: 4px;
}

.chart-bounds {
  height: 400px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.chart-viewport rect {
  fill: var(--bar-color);
}

.chart-viewport .bar-value {
  fill: var(--bar-color);
}

.chart-viewport .avg-line {
  stroke: var(--line-color);
  stroke-width: 3px;
  fill: none;
}

.chart-viewport text {
  font-size: 0.625rem;
  font-family: var(--font-monospace);
  text-anchor: end;
}

.x-axis,
.y-axis {
  color: var(--axis-color);
}

.x-axis text,
.y-axis text {
  /* font-family: 'Courier New', Courier, monospace; */
  /* font-weight: bold; */
}

.grid line,
.grid .domain {
  stroke: var(--grid-color);
}

.dots {
  stroke-dasharray: 4;
  stroke-width: 1;
  stroke: var(--gray-dark);
}

/* TODO: this isn't working for some reason */
.tooltip {
  transition: all 1s ease-in-out;
}

.tooltip .tooltip-date {
  font-family: var(--para-font-family);
  font-size: 0.875rem;
}

.tooltip .tooltip-name {
  font-family: var(--para-font-family);
  font-size: 0.75rem;
}

.tooltip .tooltip-value {
  font-size: 0.75rem;
}

.tooltip rect {
  fill: var(--background-color);
  stroke-width: 1px;
  stroke: var(--gray-dark);
}

.tooltip text {
  fill: var(--font-dark);
}

.data-grade {
  font-size: 11px;
  font-weight: normal;
  margin-left: 8px;
  line-height: 20px;
  width: 20px;
  height: 20px;
  border-radius: 13px;
  text-align: center;
  border: 2px solid;
  display: inline-block;
}

.data-grade.good {
  color: green;
  border-color: green;
}

.data-grade.meh {
  color: rgb(253, 181, 46);
  border-color: rgb(253, 181, 46);
}

.data-grade.yikes {
  color: rgb(250, 50, 50);
  border-color: rgb(250, 50, 50);
}

#footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--font-light);
  padding: 24px 12px;
  background-color: var(--background-dark);
  text-align: center;
}

#footer > div {
  padding: 8px 0;
}

#footer a,
#footer a:visited {
  color: var(--font-light);
}

/* this is amazing: */
/* https://www.filamentgroup.com/lab/select-css.html */
select {
  font-size: 16px;
  padding: 6px 28px 6px 10px;

  color: var(--font-dark);

  border: 1px solid var(--gray-med);
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  background-color: var(--select-bg);
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, var(--select-bg) 0%, var(--select-to-color) 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px var(--light-blue);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: var(--font-dark);
  outline: none;
}

.picker-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: calc(var(--action-bar-height) + 16px);
  bottom: 0;
  background-color: #000;
  transition: opacity 0.3s, z-index 0.3s;
  opacity: 0;
  z-index: -1;
}

.picker-overlay.showing {
  z-index: 2;
  opacity: 0.5;
}

.picker {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: fixed;
  bottom: var(--picker-bottom-inset);
  left: 0;
  right: 50vw;
  top: calc(var(--action-bar-height) + 16px);
  background-color: var(--background-color);
  transition: opacity 0.3s, z-index 0.1s, transform 0.3s;
  transform: translateY(-50vh);
  z-index: -1;
  opacity: 0;
}

.picker.showing {
  opacity: 1;
  z-index: 2;
  transform: translateY(0vh);
}

.picker-button-container .chevron-down,
.picker-button-container .chevron-up {
  height: 18px;
  width: 18px;
}

.chevron-container {
  display: flex;
  flex-direction: column;
  width: 24px;
  align-items: flex-end;
}

.chevron-container svg {
  height: 20px;
  width: 20px;
}

.picker-button-container {
  display: flex;
  padding-right: 8px;
}

.picker-button-container .state-button {
  flex: none;
  width: 70px;
  height: 100%;
}

.picker-container {
  position: absolute;
  top: 12px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  overflow-y: scroll;
  padding-bottom: 12px;
}

.picker-state,
.picker-container .state-button {
  flex-basis: calc(20% - 13px);
  padding: 6px;
  height: calc(
    (100vh - var(--picker-bottom-inset) - var(--action-bar-height)) * 0.12 -
      13px
  );
}

@media (max-width: 1080px) {
  :root {
    --horizontal-padding: 12px;
  }

  .actionbar {
    bottom: 0;
    border-bottom: 0;
    border-top: 1px solid var(--grid-color);
    padding-bottom: calc(8px + env(safe-area-inset-bottom, 0px));
  }

  #footer {
    padding-bottom: calc(
      var(--action-bar-height) + env(safe-area-inset-bottom, 0px) + 40px
    );
  }

  .state-summary {
    padding-top: 8px;
  }

  .actionbar > select {
    width: 40%;
  }

  .actionbar > div.state-outline-container {
    width: 50%;
  }

  .state-summary > div {
    width: 100%;
  }

  .chart {
    padding: 16px 4px 16px 0px;
    flex: 1;
    flex-basis: auto;
  }

  .chart h2 {
    padding-left: 12px;
  }

  .picker-overlay {
    top: 0;
    bottom: calc(var(--action-bar-height) + 16px);
  }

  .picker {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    bottom: calc(
      var(--action-bar-height) + 16px + env(safe-area-inset-bottom, 0px)
    );
    right: 0;
    top: var(--picker-top-inset);
    transform: translateY(50vh);
  }

  .picker.showing {
    transform: translateY(0vh);
  }

  .picker-state,
  .picker-container .state-button {
    flex-basis: calc(20% - 13px);
    height: calc(
      (100vh - var(--picker-top-inset) - var(--action-bar-height)) * 0.12 - 13px
    );
  }
}

@media (display-mode: standalone) {
  .actionbar {
    padding-bottom: 32px;
  }
}

@media (max-width: 600px) {
  .actionbar > select {
    width: 50%;
  }

  .summary-disclaimer > div {
    width: 40%;
  }

  .summary-container {
    flex-wrap: wrap;
  }

  .actionbar > div.state-outline-container {
    width: 68%;
  }

  .summary-container > div {
    flex-basis: 50%;
    min-height: 0;
    min-width: 0;
    padding-bottom: 12px;
  }

  .summary-container h3 {
    margin-bottom: 4px;
  }

  .summary-container > div:not(:last-child) {
    border: 0;
  }

  .chart-bounds {
    height: 200px;
  }
}

/* TODO: colors:
 #404A47
 #192421
 #8CFAD9
 #121614
 #3CA384 */
